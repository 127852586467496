//
//
//
//
//
//
//
//
//
//
//

import EmptyDefaultText from "@/components/common/EmptyDefaultText";

export default {
  name: "EmptyJournalsCollectionCard",
  components: {
    EmptyDefaultText
  }
}
