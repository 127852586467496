//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "SectionJournalsShortGrid",
  components: {
    EmptyJournalsCollectionGrid: () => import("@/components/common/EmptyJournalsCollectionGrid"),
    JournalCard: () => import("@/components/common/JournalCard/index"),
    Section: () => import("@/components/common/Section"),
  },
  props: {
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      journals: "journals/journals",
      pending: "journals/pending",
      city: "cities/default",
    }),
    show() {
      return this.mutatedJournals.length > 0 || this.pending;
    },
    mutatedJournals() {
      if (process.browser && this.journals.length > 0) {
        function mutation(element) {
          originalArray.splice(originalArray.indexOf(element), 1);
          mutatedArray = [...mutatedArray, element];
        }
        let mutatedArray = [];
        const originalArray = [...this.journals];
        const three = originalArray.find(item => item.columns === 3);
        if (three) {
          mutation(three);
        } else {
          const two = originalArray.find(item => item.columns === 2);
          const one = originalArray.find(item => item.columns === 1);
          if (one && two) {
            mutation(one);
            mutation(two);
          } else {
            for (let i = 0; i < 3; i++) {
              const one = originalArray.find(item => item.columns === 1);
              mutation(one);
            }
          }
        }
        const two = originalArray.find(item => item.columns === 2);
        if (two) {
          mutation(two);
        } else {
          for (let i = 0; i < 2; i++) {
            const one = originalArray.find(item => item.columns === 1);
            mutation(one);
          }
        }
        const one = originalArray.find(item => item.columns === 1);
        mutation(one);
        return mutatedArray.filter(item => !!item);
      }
      return [];
    }
  },
  mounted() {
    this.$store.dispatch("journals/fetch", {
      clear: true,
      params: {
        resolution: "micro",
        lang: this.$i18n.locale,
        city_id: this.$config.singleCity ? this.city.id : null,
      }
    });
  }
};
