//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyDefaultText from "@/components/common/EmptyDefaultText";

export default {
  name: "EmptyJournalsCollectionGrid",
  components: {
    EmptyDefaultText
  },
  data() {
    return {
      showElement: false,
      showFirstElement: false,
    }
  },
  mounted() {
    this.showFirstElement = true
    this.$nextTick(() => {
      this.showElement = true
    })
  },
}
